import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import VisitorCheckIn from "../components/VisitorCheckIn";
import VisitorCheckOut from "../components/VisitorCheckOut";
import {
  Login as CheckInIcon,
  Logout as CheckOutIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from '../logo.png';

export default function Home() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOutOpen, setModalOutOpen] = useState(false);
  const nav = useNavigate();
  const state = useSelector((state) => state.reducer);

  useEffect(() => {
    if (!state.authStatus) nav("/login");
  }, []);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalOutToggle = () => {
    setModalOutOpen(!modalOutOpen);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100vh"
        gap={2}
        textAlign="center"
      >
        {/* Logo Section */}
        <Box component="img" src={logo} alt="Logo" sx={{ width: "50%", mb: 2 }} />

        {/* Welcome Message */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
          Welcome to Orizer Gate Pass
        </Typography>

        {/* Subtitle */}
        <Typography variant="subtitle1" sx={{ color: "gray", mb: 4 }}>
          Your gateway to secure and easy visitor management
        </Typography>

        {/* Buttons Section */}
        <Box display="flex" gap={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalToggle}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 120,
              height: 120,
              borderRadius: 10,
              padding: 10,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#1976d2",
              color: "#fff",
            }}
          >
            <CheckInIcon style={{ fontSize: 60, marginBottom: 10 }} />
            <Typography variant="button" style={{ fontSize: 16 }}>
              CHECK-IN
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleModalOutToggle}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 120,
              height: 120,
              borderRadius: 10,
              padding: 10,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#d32f2f",
              color: "#fff",
            }}
          >
            <CheckOutIcon style={{ fontSize: 60, marginBottom: 10 }} />
            <Typography variant="button" style={{ fontSize: 16 }}>
              CHECK-OUT
            </Typography>
          </Button>
        </Box>
      </Box>
      <VisitorCheckIn modalOpen={modalOpen} handleModalToggle={handleModalToggle} />
      <VisitorCheckOut modalOpen={modalOutOpen} handleModalToggle={handleModalOutToggle} />
    </>
  );
}
