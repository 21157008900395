import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  TablePagination,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit, Download, SaveAlt } from "@mui/icons-material";
import Context from "../store/context";
import { useSelector } from "react-redux";
import Loader from "../components/loader";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

const Employee = () => {
  const { insertGuard, getEmployee } = Context();
  const nav = useNavigate();
  const state = useSelector((state) => state.reducer);
  const imageUrl = useSelector((state) => state.reducer.imageUrl);
  const [load, setLoad] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({
    name: "",
    phone_no: "",
    image: "",
    email: "",
    address: "",
  });
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!state.authStatus) nav("/login");
    if (state.authUser?.type === 2) nav("/");
  }, [nav, state.authStatus, state.authUser?.type]);

  useEffect(() => {
    const fetchData = async () => {
      await getEmployee();
      setLoad(false);
    };
    fetchData();
  }, []);

  const handleDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleEmployeeDetailsChange = (e) => {
    const { name, value } = e.target;
    setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmitEmployeeDetails = async () => {
    try {
      const fd = new FormData();
      fd.append("name", employeeDetails.name);
      fd.append("phone_no", employeeDetails.phone_no);
      fd.append("image", employeeDetails.image);
      fd.append("email", employeeDetails.email);
      fd.append("address", employeeDetails.address);

      let response;
      if (isEditing) {
        response = await axios.put(`http://localhost:3015/api/employee/${employeeDetails.id}`, fd);
      } else {
        response = await insertGuard(fd);
      }

      console.log("Employee details submitted:", response.data);
      handleDialogToggle();
      setIsEditing(false);
      setEmployeeDetails({
        name: "",
        phone_no: "",
        image: "",
        email: "",
        address: "",
      });
    } catch (error) {
      console.error("Error submitting employee details:", error);
    }
  };

  const handleCancel = () => {
    setEmployeeDetails({
      name: "",
      phone_no: "",
      image: "",
      email: "",
      address: "",
    });
    handleDialogToggle();
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (row) => {
    setEmployeeDetails(row);
    setIsEditing(true);
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3015/api/employee/${id}`);
      setTableData(tableData.filter((row) => row.id !== id));
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const filteredData = state.employee?.filter(
    (row) =>
      row.name?.toLowerCase().includes(filter.toLowerCase()) ||
      row.phone_no?.toLowerCase().includes(filter.toLowerCase()) ||
      row.email?.toLowerCase().includes(filter.toLowerCase()) ||
      row.address?.toLowerCase().includes(filter.toLowerCase())
  );

  const csvData = filteredData.map((row) => ({
    'Sl No.': row.id,
    'Name': row.name,
    'Phone No': row.phone_no,
    'Image': row.image,
    'Email': row.email,
    'Address': row.address,
  }));

  if (load) return <Loader />;

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: 64, flexGrow: 1, padding: 24 }}
    >
      <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
        Employee Page
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <TextField
          label="Filter"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ backgroundColor: '#ccffcc', width: '60%' }} // Light green background and 60% width
        />
        <Box display="flex" alignItems="center">
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsEditing(false);
              setDialogOpen(true);
            }}
          >
            Add Employee
          </Button>
          <CSVLink
            data={csvData}
            headers={[
              { label: 'Sl No.', key: 'Sl No.' },
              { label: 'Name', key: 'Name' },
              { label: 'Phone No', key: 'Phone No' },
              { label: 'Image', key: 'Image' },
              { label: 'Email', key: 'Email' },
              { label: 'Address', key: 'Address' },
            ]}
            filename={`employees_${new Date().toISOString().slice(0, 10)}.csv`}
          >
            <IconButton color="primary">
              <SaveAlt />
            </IconButton>
          </CSVLink>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={tableHeadStyle}>Sl No.</TableCell>
            <TableCell style={tableHeadStyle}>Name</TableCell>
            <TableCell style={tableHeadStyle}>Phone No</TableCell>
            <TableCell style={tableHeadStyle}>Image</TableCell>
            <TableCell style={tableHeadStyle}>Email</TableCell>
            <TableCell style={tableHeadStyle}>Address</TableCell>
            <TableCell style={tableHeadStyle}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length > 0 ? (
            filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#fff' }}>
                  <TableCell style={tableCellStyle}>{index + 1}</TableCell>
                  <TableCell style={tableCellStyle}>{row.name}</TableCell>
                  <TableCell style={tableCellStyle}>{row.phone_no}</TableCell>
                  <TableCell style={tableCellStyle}>
                    <img
                      src={imageUrl + row.image}
                      alt={row.name}
                      width="80"
                      height="80"
                      style={{
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>{row.email}</TableCell>
                  <TableCell style={tableCellStyle}>{row.address}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogToggle}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? "Update Employee Details" : "Add Employee Details"}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={employeeDetails.name}
            onChange={handleEmployeeDetailsChange}
          />
          <TextField
            name="phone_no"
            label="Phone No"
            variant="outlined"
            fullWidth
            margin="normal"
            value={employeeDetails.phone_no}
            onChange={handleEmployeeDetailsChange}
          />
          <TextField
            name="image"
            label="Image URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={employeeDetails.image}
            onChange={handleEmployeeDetailsChange}
          />
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={employeeDetails.email}
            onChange={handleEmployeeDetailsChange}
          />
          <TextField
            name="address"
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={employeeDetails.address}
            onChange={handleEmployeeDetailsChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitEmployeeDetails}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const tableHeadStyle = {
  fontWeight: 'bold',
  backgroundColor: '#333',
  color: '#fff',
  borderBottom: '2px solid #ddd'
};

const tableCellStyle = {
  borderRight: '1px solid #ddd',
};

export default Employee;
