import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  TablePagination,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Context from "../store/context";
import { useSelector } from "react-redux";
import Loader from "../components/loader";
import VisitorCheckIn from "../components/VisitorCheckIn";
import VisitorCheckOut from "../components/VisitorCheckOut";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv"; // Import CSVLink for CSV export
import { SaveAlt } from "@mui/icons-material";

const Visitor = () => {
  const imageUrl = useSelector((state) => state.reducer.imageUrl);
  const { getVisitor } = Context();
  const state = useSelector((state) => state.reducer);
  const [load, setLoad] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOutOpen, setModalOutOpen] = useState(false);
  const nav = useNavigate();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!state.authStatus) nav("/login");
  }, [nav, state.authStatus]);

  useEffect(() => {
    getVisitor();
    if (state.visitor !== null) {
      setLoad(false);
    }
  }, []);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalOutToggle = () => {
    setModalOutOpen(!modalOutOpen);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = state.visitor?.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(filter.toLowerCase())
    )
  );

  if (load) return <Loader />;

  const headers = [
    { label: "Sl No.", key: "id" },
    { label: "Badge No", key: "badge_no" },
    { label: "Name", key: "name" },
    { label: "Type", key: "type" },
    { label: "Phone No", key: "phone_no" },
    { label: "Purpose", key: "purpose" },
    { label: "From", key: "fro_m" },
    { label: "To", key: "employee_name" },
    { label: "Date", key: "date" },
    { label: "Check-In Time", key: "in_time" },
    { label: "Check-Out Time", key: "check_out" },
    { label: "Extra Information", key: "extra_info" },
    { label: "Image", key: "image" },
  ];

  const csvData = filteredData.map((row, index) => ({
    id: index + 1,
    badge_no: row.badge_no,
    name: row.name,
    type: row.type,
    phone_no: row.phone_no,
    purpose: row.purpose,
    fro_m: row.fro_m,
    employee_name: row.employee_name,
    date: row.date,
    in_time: row.in_time,
    check_out: row.check_out,
    extra_info: `${row.vehicle_no ? "Vehicle No.: " + row.vehicle_no + " " : ""} ${row.aadhar_no ? "Aadhaar No.: " + row.aadhar_no + " " : ""} ${row.no_of_worker ? "No. of Worker: " + row.no_of_worker + " " : ""} ${row.hrs ? "Hours: " + row.hrs + " " : ""} ${row.rate ? "Rate: " + row.rate : ""}`,
    image: row.image_in ? "Image present" : "No Image"
  }));

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: 64, flexGrow: 1, padding: 24 }}
    >
      <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
        Visitor Page
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <TextField
          label="Filter"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ backgroundColor: "#d0f0c0", width: "60%" }} // Light green background and 60% width
        />
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalToggle}
          >
            Check-In
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalOutToggle}
          >
            Check-Out
          </Button>
          <IconButton color="primary">
            <CSVLink
              data={csvData}
              headers={headers}
              filename="visitor.csv"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <SaveAlt />
            </CSVLink>
          </IconButton>
        </Box>
      </Box>
      <Table>
        <TableHead style={{ backgroundColor: "#333", color: "#fff", fontWeight: 700 }}>
          <TableRow>
            <TableCell style={tableHeadStyle}>Sl No.</TableCell>
            <TableCell style={tableHeadStyle}>Badge No</TableCell>
            <TableCell style={tableHeadStyle}>Name</TableCell>
            <TableCell style={tableHeadStyle}>Type</TableCell>
            <TableCell style={tableHeadStyle}>Phone No</TableCell>
            <TableCell style={tableHeadStyle}>Purpose</TableCell>
            <TableCell style={tableHeadStyle}>From</TableCell>
            <TableCell style={tableHeadStyle}>To</TableCell>
            <TableCell style={tableHeadStyle}>Date</TableCell>
            <TableCell style={tableHeadStyle}>Check-In Time</TableCell>
            <TableCell style={tableHeadStyle}>Check-Out Time</TableCell>
            <TableCell style={tableHeadStyle}>Extra Information</TableCell>
            <TableCell style={tableHeadStyle}>Image</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length > 0 ? (
            filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff" }}>
                  <TableCell style={tableCellStyle}>{index + 1}</TableCell>
                  <TableCell style={tableCellStyle}>{row.badge_no}</TableCell>
                  <TableCell style={tableCellStyle}>{row.name}</TableCell>
                  <TableCell style={tableCellStyle}>{row.type}</TableCell>
                  <TableCell style={tableCellStyle}>{row.phone_no}</TableCell>
                  <TableCell style={tableCellStyle}>{row.purpose}</TableCell>
                  <TableCell style={tableCellStyle}>{row.fro_m}</TableCell>
                  <TableCell style={tableCellStyle}>
                    {row.employee_name} ({row.employee_phone_no})
                  </TableCell>
                  <TableCell style={tableCellStyle}>{row.date}</TableCell>
                  <TableCell style={tableCellStyle}>{row.in_time}</TableCell>
                  <TableCell style={tableCellStyle}>{row.check_out}</TableCell>
                  <TableCell style={tableCellStyle}>
                    {row.vehicle_no && "Vehicle No.: " + row.vehicle_no} <br />
                    {row.aadhar_no && "Aadhaar No.: " + row.aadhar_no} <br />
                    {row.no_of_worker && "No. of Worker: " + row.no_of_worker} <br />
                    {row.hrs && "Hours: " + row.hrs} <br />
                    {row.rate && "Rate: " + row.rate}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>
                    <img
                      src={imageUrl + row.image_in}
                      alt={row.name}
                      width="80"
                      style={{ borderRadius: "5px" }}
                    />
                    {row.type === "vehicle" && (
                      <img
                        src={imageUrl + row.image_out}
                        alt={row.name}
                        width="80"
                        style={{ borderRadius: "5px" }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={13} align="center">
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <VisitorCheckIn
        modalOpen={modalOpen}
        handleModalToggle={handleModalToggle}
      />
      <VisitorCheckOut
        modalOpen={modalOutOpen}
        handleModalToggle={handleModalOutToggle}
      />
    </Container>
  );
};

const tableHeadStyle = {
  fontWeight: 'bold',
  backgroundColor: '#333',
  color: '#fff',
  borderBottom: '2px solid #ddd'
};

const tableCellStyle = {
  borderRight: '1px solid #ddd',
};
export default Visitor;
