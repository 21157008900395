import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  TablePagination,
  Typography,
  IconButton
} from '@mui/material';
import { useSelector } from 'react-redux';
import Context from '../store/context';
import Loader from '../components/loader';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Download, SaveAlt } from '@mui/icons-material';

const Log = () => {
  const { getLog } = Context();
  const nav = useNavigate();
  const state = useSelector((state) => state.reducer);

  const [load, setLoad] = useState(true);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!state.authStatus) nav('/login');
    if (state.authUser?.type === 2) nav('/');
  }, [nav, state.authStatus, state.authUser?.type]);

  useEffect(() => {
    const fetchData = async () => {
      await getLog();
      setLoad(false);
    };
    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = state.log?.filter((row) => {
    const effectiveId = row.effective_id?.toString().toLowerCase();
    const description = row.description?.toString().toLowerCase();
    const by = row.b_y?.toString().toLowerCase();
    const filterText = filter?.toLowerCase();

    return (
      effectiveId.includes(filterText) ||
      description.includes(filterText) ||
      by.includes(filterText)
    );
  });

  const csvData = filteredData.map((row) => ({
    'Sl No.': row.id, // Add the index or another unique identifier for the "Sl No."
    'Effective ID': row.effective_id,
    'Description': row.description,
    'By': row.b_y,
  }));

  if (load) return <Loader />;

  return (
    <Container maxWidth="xl" style={{ marginTop: 64, flexGrow: 1, padding: 24 }}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
        Logs
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label="Filter"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ backgroundColor: '#ccffcc', width: '60%' }} // Light green background and 60% width
        />
        <CSVLink
          data={csvData}
          headers={[
            { label: 'Sl No.', key: 'Sl No.' },
            { label: 'Effective ID', key: 'Effective ID' },
            { label: 'Description', key: 'Description' },
            { label: 'By', key: 'By' }
          ]}
          filename={`logs_${new Date().toISOString().slice(0, 10)}.csv`}
        >
          <IconButton color="primary">
            <SaveAlt />
          </IconButton>
        </CSVLink>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={tableHeadStyle}>Sl No.</TableCell>
            <TableCell style={tableHeadStyle}>Effective ID</TableCell>
            <TableCell style={tableHeadStyle}>Description</TableCell>
            <TableCell style={tableHeadStyle}>By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length > 0 ? (
            filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#fff' }}>
                <TableCell style={tableCellStyle}>{index + 1}</TableCell>
                <TableCell style={tableCellStyle}>{row.effective_id}</TableCell>
                <TableCell style={tableCellStyle}>{row.description}</TableCell>
                <TableCell style={tableCellStyle}>{row.b_y}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

const tableHeadStyle = {
  fontWeight: 'bold',
  backgroundColor: '#333',
  color: '#fff',
  borderBottom: '2px solid #ddd'
};

const tableCellStyle = {
  borderRight: '1px solid #ddd',
};

export default Log;
