import React, { useEffect, useState, useRef } from "react";
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person"; // Icon for Visitor
import SecurityIcon from "@mui/icons-material/Security"; // Icon for Guards
import GroupIcon from "@mui/icons-material/Group"; // Icon for Employees
import ListIcon from "@mui/icons-material/List"; // Icon for Logs
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Context from "../store/context";

const Header = () => {
  const { logout } = Context();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null); // Ref for the sidebar

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  // Close sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    if (sidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarOpen]);

  const state = useSelector((state) => state.reducer);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
        ref={sidebarRef}
        PaperProps={{
          style: { backgroundColor: "#4CAF50", color: "white" }, // Green background
        }}
      >
        <Box width={250} style={{ marginTop: "70px" }}>
          {/* Sidebar content with icons */}
          
        <Link
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderBottom: "1px solid #ffffff50",
              }}
              to="/dashboard"
              onClick={handleCloseSidebar}
            >
              <AssignmentIcon style={{ marginRight: "10px", color: "white" }} />
              <Typography
                style={{
                  fontWeight: 600,
                  color: "white",
                }}
                variant="h6"
                gutterBottom
              >
                Dashboard
              </Typography>
            </Link>
          {state.authStatus && (
            <Link
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderBottom: "1px solid #ffffff50",
              }}
              to="/visitor"
              onClick={handleCloseSidebar}
            >
              <PersonIcon style={{ marginRight: "10px", color: "white" }} />
              <Typography
                style={{
                  fontWeight: 600,
                  color: "white",
                }}
                variant="h6"
                gutterBottom
              >
                Visitor
              </Typography>
            </Link>
          )}
          {state.authUser?.type === 1 && (
            <>
              <Link
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  borderBottom: "1px solid #ffffff50",
                }}
                to="/guard"
                onClick={handleCloseSidebar}
              >
                <SecurityIcon style={{ marginRight: "10px", color: "white" }} />
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "white",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Guards
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #ffffff50",
                  padding: "10px",
                }}
                to="/employee"
                onClick={handleCloseSidebar}
              >
                <GroupIcon style={{ marginRight: "10px", color: "white" }} />
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "white",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Employees
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #ffffff50",
                  padding: "10px",
                }}
                to="/log"
                onClick={handleCloseSidebar}
              >
                <ListIcon style={{ marginRight: "10px", color: "white" }} />
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "white",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Logs
                </Typography>
              </Link>
            </>
          )}
          {state.authStatus && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                logout();
                handleCloseSidebar();
              }}
              style={{ margin: "10px", width: "-webkit-fill-available" }}
            >
              Logout
            </Button>
          )}
        </Box>
      </Drawer>
      <AppBar
        position="fixed"
        style={{ zIndex: 1201, backgroundColor: "#4CAF50" }} // Green AppBar
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleSidebarToggle}
          >
            {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>

          <Link style={{ textDecoration: "none" }} to="/">
            <Typography variant="h6" style={{ flexGrow: 1, color: "white" }}>
              Orizer | Gate-Pass
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
