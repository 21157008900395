import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box, createTheme, ThemeProvider } from '@mui/material';
import logo from '../logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Context from '../store/context';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50', // Green color
    },
  },
});

const Login = () => {
  const nav = useNavigate();
  const { login, forgetPassword } = Context();
  const [view, setView] = useState('login'); // Track current view: 'login', 'forgot', 'otp', 'reset'
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [sendOtp, setSendOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handle login operation  
  const handleLogin = async () => {
    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    formData.append('password', password);

    try {
      const response = await login(formData);
      console.log(response);
      // handle success
      nav('/');
    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  // Handle OTP sending
  const handleSendOtp = async () => {
    try {
      let minm = 10000;
      let maxm = 99999;
      let otp = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
      setSendOtp(otp);
      console.log("OTP: " + otp);
      /** const response = await axios.post('/send-otp', { phoneNumber });
      console.log('OTP sent:', response.data); */
      setView('otp'); // Move to OTP view
    } catch (error) {
      console.error(error);
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async () => {
    try {
      console.log({ otp, sendOtp })
      if (parseInt(otp) === sendOtp)
        setView('reset'); // Move to Reset Password view
    } catch (error) {
      console.error(error);
    }
  };

  // Handle password reset
  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('phoneNumber', phoneNumber);
      formData.append('password', newPassword);
      const response = await forgetPassword(formData);
      console.log('Password reset successful:', response.data);
      setView('login'); // Back to login view after successful reset
    } catch (error) {
      console.error(error);
    }
  };

  // Conditionally render based on current view
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <img src={logo} alt="logo" style={{ marginBottom: '20px' }} />
          {view === 'login' && (
            <>
              <Typography variant="h4" component="h1" gutterBottom>
                Log In
              </Typography>
              <Box width="100%">
                {/* Phone number field */}
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {/* Password field */}
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* Forgot Password Link just below Password field */}
                <Box textAlign="right" mt={1} mb={2}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setView('forgot')}
                    size="small"
                  >
                    Forgot Password?
                  </Button>
                </Box>
              </Box>
              {/* Login button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
              >
                Login
              </Button>
            </>
          )}

          {view === 'forgot' && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                Forgot Password
              </Typography>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSendOtp}
              >
                Send OTP
              </Button>
              <Box textAlign="right" mt={2}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setView('login')}
                  size="small"
                >
                  Back to Login
                </Button>
              </Box>
            </>
          )}

          {view === 'otp' && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                Enter OTP
              </Typography>
              <TextField
                label="OTP"
                variant="outlined"
                fullWidth
                margin="normal"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Box textAlign="right" mt={1} mb={2}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setView('forgot')}
                  size="small"
                >
                  Change Number?
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </Button>
            </>
          )}

          {view === 'reset' && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                Reset Password
              </Typography>
              <TextField
                label="New Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                label="Confirm Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
