import React from 'react';
import { FaUser, FaCar, FaToolbox, FaUsers, FaUserTie, FaShieldAlt } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Box, Grid, Typography, Paper, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// Register Chart.js components
ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
    const stateData = useSelector(state => state.reducer);
    console.log(stateData)
   const disitinctDate = [...new Set(stateData.visitor.map(item => item.date))].sort((a, b) => new Date(a) - new Date(b)).slice(-10);
  const data = {
    labels: disitinctDate,
    datasets: [
        {
          label: 'Peoples',
          data: [...disitinctDate.map( data =>  stateData.visitor.filter(item => item.date === data && item.type === "people")?.length )], // Sample data, replace with real data
          fill: false,
          backgroundColor: 'rgba(63,81,181,0.8)',
          borderColor: 'rgba(63,81,181,1)',
        },
        {
          label: 'Vehicles',
          data: [...disitinctDate.map( data =>  stateData.visitor.filter(item => item.date === data && item.type === "vehicle")?.length )], // Sample data, replace with real data
          fill: false,
          backgroundColor: 'rgba(244,67,54,0.8)',
          borderColor: 'rgba(244,67,54,1)',
        },
        {
          label: 'Contractors',
          data: [...disitinctDate.map( data =>  stateData.visitor.filter(item => item.date === data && item.type === "contractor")?.length )], // Sample data, replace with real data
          fill: false,
          backgroundColor: 'rgba(76,175,80,0.8)',
          borderColor: 'rgba(76,175,80,1)',
        },
        {
          label: 'Overall Visitors',
          data: [...disitinctDate.map( data =>  stateData.visitor.filter(item => item.date === data )?.length )], // Sample data, replace with real data
          fill: false,
          backgroundColor: 'rgba(255,152,0,0.8)',
          borderColor: 'rgba(255,152,0,1)',
        },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: 64, flexGrow: 1, padding: 24 }}
    >
    <Box sx={{ padding: 2, fontFamily: 'Arial' }}>
      {/* Top Squares */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={3}>
          <InfoBox bgColor="#3f51b5"> {/* Blue */}
            <FaUser size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white', fontSize: "medium", mt: 1 }}>People</Typography>
            <Typography variant="h5" sx={{ color: 'white', fontWeight: 600 }}>{stateData.visitor.filter(data => data.type === "people")?.length}</Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={3}>
          <InfoBox bgColor="#f44336"> {/* Red */}
            <FaCar size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white',fontSize: "medium", mt: 1 }}>Vehicles</Typography>
            <Typography variant="h5" sx={{ color: 'white',fontWeight: 600  }}>{stateData.visitor.filter(data => data.type === "vehicle")?.length}</Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={3}>
          <InfoBox bgColor="#4caf50"> {/* Green */}
            <FaToolbox size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white',fontSize: "medium", mt: 1 }}>Contractors</Typography>
            <Typography variant="h5" sx={{ color: 'white',fontWeight: 600 }}>{stateData.visitor.filter(data => data.type === "contractor")?.length}</Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={3}>
          <InfoBox bgColor="#ff9800"> {/* Orange */}
            <FaUsers size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white',fontSize: "medium", mt: 1 }}>Overall Visitors</Typography>
            <Typography variant="h5" sx={{ color: 'white',fontWeight: 600 }}>{stateData.visitor?.length}</Typography>
          </InfoBox>
        </Grid>
      </Grid>

      {/* Graph and Right-side boxes */}
      <Grid container spacing={2}>
        {/* Graph */}
        <Grid item xs={9}>
          <Paper sx={{ padding: 2, height: 300, borderRadius: 2 }}>
            <Typography variant="h6" mb={2}>Visitors Per Day</Typography>
            <Box sx={{ height: '100%' }}>
              <Line data={data} options={options} />
            </Box>
          </Paper>
        </Grid>

        {/* Employee and Guard Boxes */}
        <Grid item xs={3}>
          <InfoBox bgColor="#2196f3"> {/* Blue */}
            <FaUserTie size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white',fontSize: "medium", mt: 1 }}>Total Employees</Typography>
            <Typography variant="h5" sx={{ color: 'white',fontWeight: 600 }}>{stateData.employee?.length}</Typography>
          </InfoBox>
          <InfoBox bgColor="#9c27b0"> {/* Purple */}
            <FaShieldAlt size={120} color="white" />
            <Typography variant="h6" sx={{ color: 'white',fontSize: "medium", mt: 1 }}>Total Guards</Typography>
            <Typography variant="h5" sx={{ color: 'white',fontWeight: 600 }}>{stateData.guard?.length}</Typography>
          </InfoBox>
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
};

// Styled Components
const InfoBox = styled(Paper)(({ theme, bgColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: bgColor,
  boxShadow: theme.shadows[2],
}));

export default Dashboard;
