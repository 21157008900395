import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  TablePagination,
  Typography,
  IconButton,
} from '@mui/material';
import { CSVLink } from 'react-csv'; // CSV Export
import axios from 'axios';
import { Delete, Edit, SaveAlt } from '@mui/icons-material'; // Export icon
import { useSelector } from 'react-redux';
import Context from '../store/context';
import Loader from '../components/loader';
import { useNavigate } from 'react-router-dom';

const User = () => {
  const { insertGuard, getGuard, updateGuard } = Context();
  const state = useSelector((state) => state.reducer);
  const [load, setLoad] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: '',
    phone_no: '',
    email: '',
    image: '',
    blood_group: '',
    agency: '',
    address: '',
    password: '',
    c_password: '',
  });
  const [filter, setFilter] = useState('');
  const nav = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Authentication check
  useEffect(() => {
    if (!state.authStatus) {
      nav('/login');
    }
    if (state.authUser?.type === 2) {
      nav('/');
    }
  }, [state.authStatus, state.authUser, nav]);

  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      await getGuard();
      setLoad(false);
    };
    fetchData();
  }, []);

  // Toggle dialog
  const handleDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  // Handle form changes
  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Submit form data
  const handleSubmitUserDetails = async () => {
    try {
      const fd = new FormData();
      fd.append('name', userDetails.name);
      fd.append('phone_no', userDetails.phone_no);
      fd.append('email', userDetails.email);
      fd.append('address', userDetails.address);
      fd.append('blood_group', userDetails.blood_group);
      fd.append('agency', userDetails.agency);
      if (isEditing) {
        fd.append('password', userDetails.password);
        fd.append('id', userDetails.id);
      }
      const response = await (!isEditing ? insertGuard(fd) : updateGuard(fd));
      console.log('User details submitted:', response.data);
      handleDialogToggle();
    } catch (error) {
      console.error('Error submitting user details:', error);
    }
  };

  const handleCancel = () => {
    setUserDetails({
      name: '',
      phone_no: '',
      email: '',
      blood_group: '',
      agency: '',
      address: '',
      password: '',
      c_password: '',
    });
    handleDialogToggle();
  };

  // Filter functionality
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle Edit
  const handleEdit = (row) => {
    setUserDetails(row);
    setIsEditing(true);
    setDialogOpen(true);
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3015/api/guard/${id}`);
      getGuard(); // Re-fetch data after delete
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const filteredData = state.guard?.filter(
    (row) =>
      row.name.toLowerCase().includes(filter.toLowerCase()) ||
      row.email.toLowerCase().includes(filter.toLowerCase()) ||
      row.phone_no.toLowerCase().includes(filter.toLowerCase()) ||
      row.agency.toLowerCase().includes(filter.toLowerCase()) ||
      row.blood_group.toLowerCase().includes(filter.toLowerCase()) ||
      row.address.toLowerCase().includes(filter.toLowerCase())
  );

  if (load) return <Loader />;

  // CSV Export Data
  const csvData = filteredData.map((row, index) => ({
    SlNo: index + 1,
    Name: row.name,
    PhoneNo: row.phone_no,
    Email: row.email,
    BloodGroup: row.blood_group,
    Agency: row.agency,
    Address: row.address,
  }));

  return (
    <Container maxWidth="xl" style={{ marginTop: 64, flexGrow: 1, padding: 24 }}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
        Guards
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        {/* Filter Input Field with Light Green Background and 60% Width */}
        <TextField
          label="Filter"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ backgroundColor: '#ccffcc', width: '60%' }} // Light green background and 60% width
        />
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsEditing(false);
              setDialogOpen(true);
            }}
          >
            Add Guard
          </Button>
          
          <IconButton color="primary">
            <CSVLink data={csvData} filename="guards.csv" style={{ textDecoration: 'none', color: 'inherit' }}>
              <SaveAlt />
            </CSVLink>
          </IconButton>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={tableHeadStyle}>Sl No.</TableCell>
            <TableCell style={tableHeadStyle}>Name</TableCell>
            <TableCell style={tableHeadStyle}>Phone No</TableCell>
            <TableCell style={tableHeadStyle}>Email</TableCell>
            <TableCell style={tableHeadStyle}>Image</TableCell>
            <TableCell style={tableHeadStyle}>Blood Group</TableCell>
            <TableCell style={tableHeadStyle}>Agency</TableCell>
            <TableCell style={tableHeadStyle}>Address</TableCell>
            <TableCell style={tableHeadStyle}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length > 0 ? (
            filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#fff' }}
              >
                <TableCell style={tableCellStyle}>{index + 1}</TableCell>
                <TableCell style={tableCellStyle}>{row.name}</TableCell>
                <TableCell style={tableCellStyle}>{row.phone_no}</TableCell>
                <TableCell style={tableCellStyle}>{row.email}</TableCell>
                <TableCell style={tableCellStyle}>
                  <img
                    src={row.image}
                    alt={row.name}
                    width="80"
                    height="80"
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                  />
                </TableCell>
                <TableCell style={tableCellStyle}>{row.blood_group}</TableCell>
                <TableCell style={tableCellStyle}>{row.agency}</TableCell>
                <TableCell style={tableCellStyle}>{row.address}</TableCell>
                <TableCell style={tableCellStyle}>
                  <IconButton onClick={() => handleEdit(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center">
                No guards found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for Add/Edit */}
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>{isEditing ? 'Edit Guard' : 'Add Guard'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            margin="dense"
            name="name"
            value={userDetails.name}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          <TextField
            label="Phone No"
            variant="outlined"
            margin="dense"
            name="phone_no"
            value={userDetails.phone_no}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="dense"
            name="email"
            value={userDetails.email}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          <TextField
            label="Address"
            variant="outlined"
            margin="dense"
            name="address"
            value={userDetails.address}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          <TextField
            label="Blood Group"
            variant="outlined"
            margin="dense"
            name="blood_group"
            value={userDetails.blood_group}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          <TextField
            label="Agency"
            variant="outlined"
            margin="dense"
            name="agency"
            value={userDetails.agency}
            onChange={handleUserDetailsChange}
            fullWidth
          />
          {!isEditing && (
            <>
              <TextField
                label="Password"
                variant="outlined"
                margin="dense"
                type="password"
                name="password"
                value={userDetails.password}
                onChange={handleUserDetailsChange}
                fullWidth
              />
              <TextField
                label="Confirm Password"
                variant="outlined"
                margin="dense"
                type="password"
                name="c_password"
                value={userDetails.c_password}
                onChange={handleUserDetailsChange}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitUserDetails} color="primary">
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const tableHeadStyle = {
  fontWeight: 'bold',
  backgroundColor: '#333',
  color: '#fff',
  borderBottom: '2px solid #ddd'
};

const tableCellStyle = {
  borderRight: '1px solid #ddd',
};

export default User;
